import { createFeatureSelector, createSelector } from '@ngrx/store'
import {
  CURRENT_USER_FEATURE_KEY,
  CurrentUserState,
  roleAdapter,
  tenantAdapter
} from './current-user.reducer'

// Lookup the 'CurrentUser' feature state managed by NgRx
export const selectCurrentUserState = createFeatureSelector<CurrentUserState>(
  CURRENT_USER_FEATURE_KEY
)

const currentUserRoleSelector = roleAdapter.getSelectors()
const currentUserTenantSelector = tenantAdapter.getSelectors()

export const selectAllCurrentUserRoles = createSelector(
  selectCurrentUserState,
  (state: CurrentUserState) => currentUserRoleSelector.selectAll(state.roles)
)

export const selectCurrentUserRolesEntities = createSelector(
  selectCurrentUserState,
  (state: CurrentUserState) =>
    currentUserRoleSelector.selectEntities(state.roles)
)

export const selectAllCurrentUserTenants = createSelector(
  selectCurrentUserState,
  (state: CurrentUserState) =>
    currentUserTenantSelector.selectAll(state.tenants)
)

export const selectCurrentUserTenantsEntities = createSelector(
  selectCurrentUserState,
  (state: CurrentUserState) =>
    currentUserTenantSelector.selectEntities(state.tenants)
)

export const selectCurrentUserCurrentTenantId = createSelector(
  selectCurrentUserState,
  (state: CurrentUserState) => state.currentTenantId
)
export const selectCurrentUserCurrentTenant = createSelector(
  selectCurrentUserState,
  selectCurrentUserCurrentTenantId,
  (state: CurrentUserState, currentTenantId: number | undefined) =>
    state.tenants.entities[currentTenantId ?? -1]
)

export const selectLoading = createSelector(
  selectCurrentUserState,
  (state: CurrentUserState) => state.loading
)

export const selectMenu = createSelector(
  selectCurrentUserState,
  (state: CurrentUserState) => state.menuGroups
)

export const selectCurrentUserProfile = createSelector(
  selectCurrentUserState,
  (state: CurrentUserState) => state.userProfile
)

export const selectCurrentFeatureFlags = createSelector(
  selectCurrentUserState,
  (state: CurrentUserState) => state.featureFlags
)
