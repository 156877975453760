import { inject } from '@angular/core'
import { CurrentUserFacade } from '../+state/current-user.facade'

export function injectCurrentUserMainKeys() {
  const currentUserFacade = inject(CurrentUserFacade)

  return {
    tenantUuid: currentUserFacade.currentTenant()?.uuid ?? 'default',
    userUuid: currentUserFacade.userProfile()?.guid ?? 'default'
  }
}
