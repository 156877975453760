import { Provider } from '@angular/core'
import { provideIcons } from '@ng-icons/core'
import {
  heroBriefcase,
  heroCog8Tooth,
  heroCube,
  heroDocument,
  heroDocumentChartBar,
  heroInbox,
  heroQueueList,
  heroTruck,
  heroUser,
  heroUserGroup,
  heroDocumentText,
  heroPuzzlePiece,
  heroDocumentDuplicate,
  heroDocumentCheck,
  heroScale,
  heroFlag
} from '@ng-icons/heroicons/outline'

export const KnownMenuIcons: Record<string, string> = {
  invoice: 'heroInbox',
  customer: 'heroUser',
  vendor: 'heroTruck',
  user: 'heroUserGroup',
  tenant: 'heroBriefcase',
  role: 'heroCog8Tooth',
  order: 'heroCube',
  reports: 'heroDocumentChartBar',
  automation: 'heroQueueList',
  document: 'heroDocument',
  'charge-codes': 'heroDocumentText',
  unmatched: 'heroPuzzlePiece',
  duplicates: 'heroDocumentDuplicate',
  'audit-invoices': 'heroScale',
  'audit-disputes': 'heroFlag',
  'analysis-invoices': 'heroDocumentCheck',
  dispute: 'heroInbox'
}

export function provideMenuIcons(): Provider {
  return provideIcons({
    heroInbox,
    heroUser,
    heroTruck,
    heroUserGroup,
    heroBriefcase,
    heroCog8Tooth,
    heroCube,
    heroDocumentChartBar,
    heroQueueList,
    heroDocument,
    heroDocumentText,
    heroPuzzlePiece,
    heroDocumentDuplicate,
    heroFlag,
    heroDocumentCheck,
    heroScale
  })
}
