import { Injectable, inject } from '@angular/core'
import { createEffect, Actions, ofType } from '@ngrx/effects'
import {
  switchMap,
  map,
  catchError,
  of,
  finalize,
  exhaustMap,
  tap,
  take
} from 'rxjs'
import {
  currentUserActions,
  currentUserRolesActions,
  currentUserTenantsActions
} from './current-user.actions'
import { CurentUserService } from '../infrastructure/current-user.service'
import { FromGetCurrentUserRoles } from '../adapter/FromGetCurrentUserRoles'
import { FromGetCurrentUserTenants } from '../adapter/FromGetCurrentUserTenants'

import { CurrentUserFacade } from './current-user.facade'
import { AsyncOperations } from '../domain/current-user-loading.model'
import { FromGetCurrentUserMenu } from '../adapter/FromGetCurrentUserMenu'

import { AuthService } from '@auth0/auth0-angular'
import { FromGetUserProfileResponse } from '../adapter/FromGetUserProfileResponse'

import { ToUpdateUserProfileRequest } from '../adapter/ToUpdateCurrentCustomerRequest'
import { CurrentUserAdapter } from '../adapter/currentUserAdapter'

@Injectable()
export class CurrentUserEffects {
  private adapter = new CurrentUserAdapter()

  private actions$ = inject(Actions)
  private service = inject(CurentUserService)
  private readonly currentUserFacade = inject(CurrentUserFacade)
  private readonly authService = inject(AuthService)

  constructor() {
    this.adapter.set(FromGetCurrentUserRoles)
    this.adapter.set(FromGetCurrentUserTenants)
    this.adapter.set(FromGetUserProfileResponse)
    this.adapter.set(FromGetCurrentUserMenu)
    this.adapter.set(ToUpdateUserProfileRequest)
  }

  loadCurrentUserRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentUserRolesActions.loadRoles),
      switchMap(() => this.service.getCurrentUserRoles()),
      map(result => this.adapter.convert(FromGetCurrentUserRoles, result)),
      map(currentUser =>
        currentUserRolesActions.loadRolesSuccess({ roles: currentUser.roles })
      )
    )
  )

  loadCurrentUserTenats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentUserTenantsActions.loadTenants),
      switchMap(() => this.service.getCurrentUserTenants()),
      map(result => this.adapter.convert(FromGetCurrentUserTenants, result)),
      map(currentUser =>
        currentUserTenantsActions.loadTenantsSuccess({
          tenants: currentUser.tenants
        })
      )
    )
  )

  loadCurrentTenantDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentUserTenantsActions.loadCurrentTenantDetails),
      switchMap(() => this.service.getCurrentTenantDetails()),
      map(response =>
        currentUserTenantsActions.loadCurrentTenantDetailsSuccess({
          tenant: {
            description: response.description,
            id: response.tenantId,
            uuid: response.tenantUuid,
            logoUrl: response.logoUrl,
            squareLogoUrl: response.squareLogoUrl,
            notificationEmailSender: response.notificationEmailSender,
            disputeVersion: response.disputeVersion,
            orderVersion: response.ordersVersion,
            isActive: response.isActive,
            orderLineItemAssociation: response.orderLineItemAssociation,
            disputeEmailSetupCompleteFlag:
              response.disputeEmailSetupCompleteFlag,
            isChargeMappingToggleDefaulted:
              response.isChargeMappingToggleDefaulted
          }
        })
      )
    )
  )

  loadUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentUserActions.loadUserProfile),
      switchMap(() =>
        this.service.getUserProfile().pipe(
          map(response =>
            this.adapter.convert(FromGetUserProfileResponse, response)
          ),
          map(userProfile =>
            currentUserActions.loadUserProfileSuccess({ userProfile })
          ),
          catchError(error =>
            of(currentUserActions.loadUserProfileFail({ error }))
          ),
          finalize(() =>
            this.currentUserFacade.endLoading(AsyncOperations.getUserProfile)
          )
        )
      )
    )
  )

  updateCurrentUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentUserActions.updateUserProfile),
      map(request =>
        this.adapter.convert(ToUpdateUserProfileRequest, request.profile)
      ),
      exhaustMap(request =>
        this.service.updateTenantDetails(request).pipe(
          map(() => currentUserActions.updateUserProfileSuccess()),
          catchError(httpError =>
            of(
              currentUserActions.updateUserProfileFail({
                error: httpError.error
              })
            )
          ),
          finalize(() =>
            this.currentUserFacade.endLoading(AsyncOperations.updateDetails)
          )
        )
      )
    )
  )

  updateUserProfileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentUserActions.updateUserProfileSuccess),
      map(() => currentUserActions.loadUserProfile())
    )
  )

  loadCurrentUserMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentUserActions.loadCurrentUserMenu),
      switchMap(() =>
        this.service.getCurrentUserMenu().pipe(
          map(response => ({
            newMenu: this.adapter.convert(FromGetCurrentUserMenu, response)
          })),
          map(menu =>
            currentUserActions.loadCurrentUserMenuSuccess({
              menu: menu.newMenu
            })
          ),
          catchError(error =>
            of(currentUserActions.loadCurrentUserMenuFail({ error }))
          ),
          finalize(() =>
            this.currentUserFacade.endLoading(AsyncOperations.getMenu)
          )
        )
      )
    )
  )

  switchCurrentTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentUserActions.switchCurrentTenant),
      exhaustMap(request =>
        this.service.switchTenant(request.tenantId).pipe(
          map(() =>
            currentUserActions.switchCurrentTenantSuccess({
              tenantId: request.tenantId
            })
          ),
          catchError(httpError =>
            of(
              currentUserActions.switchCurrentTenantFail({
                error: httpError.error
              })
            )
          ),
          finalize(() =>
            this.currentUserFacade.endLoading(AsyncOperations.switchTenant)
          )
        )
      )
    )
  )

  loginUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentUserActions.loginUser),
      exhaustMap(({ email }) =>
        this.service.getOrganizationId(email).pipe(
          map(({ organizationId }) =>
            currentUserActions.loginUserSuccess({ organizationId, email })
          ),
          catchError(httpError =>
            of(
              currentUserActions.loginUserFail({
                error: httpError.error
              })
            )
          ),
          finalize(() =>
            this.currentUserFacade.endLoading(AsyncOperations.login)
          )
        )
      )
    )
  )

  loginUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(currentUserActions.loginUserSuccess),
        take(1),
        tap(({ organizationId, email }) =>
          this.authService.loginWithRedirect({
            authorizationParams: {
              organization: organizationId,
              email,
              login_hint: email
            }
          })
        )
      ),
    { dispatch: false }
  )

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentUserActions.resetPassword),
      switchMap(({ email }) =>
        this.service.forgotPassword(email).pipe(
          map(() => currentUserActions.resetPasswordSuccess()),
          catchError(error =>
            of(currentUserActions.resetPasswordFail({ error }))
          )
        )
      )
    )
  )
}
