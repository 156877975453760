import { Injectable, computed, inject } from '@angular/core'
import { Store, select } from '@ngrx/store'

import {
  currentUserActions,
  currentUserRolesActions,
  currentUserTenantsActions
} from './current-user.actions'

import { Observable, filter, firstValueFrom, map, withLatestFrom } from 'rxjs'
import { FeatureFlags, FeatureFlagsState } from '../domain/feature-flags.model'
import { Tenant } from '../domain/tenant.model'
import { AsyncOperations } from '../domain/current-user-loading.model'
import { LoadingStatuses } from '@navix/shared/loading'
import { CurrentUserForm } from '../domain/current-user-form.model'

import { UserProfile } from '../domain/user-profile.model'
import { FeatureFlagService } from '../infrastructure/feature-flag.service'
import { MenuGroup } from '../domain/menu-group.model'
import * as CurrentUserSelectors from './current-user.selectors'
import { toSignal } from '@angular/core/rxjs-interop'
import { LDFlagSet } from 'launchdarkly-js-sdk-common'

@Injectable()
export class CurrentUserFacade {
  private readonly store = inject(Store)
  private readonly featureFlagService = inject(FeatureFlagService)

  // temporalTenant$ = this.store.pipe(select(x => (x as any)?.app?.tenant))

  allFeatureFlags$ = this.store.pipe(
    select(CurrentUserSelectors.selectCurrentFeatureFlags)
  )
  allFeatureFlags = this.store.selectSignal(
    CurrentUserSelectors.selectCurrentFeatureFlags
  )

  allCurrentUserRoles$ = this.store
    .select(CurrentUserSelectors.selectAllCurrentUserRoles)
    .pipe(
      filter(roles => roles.length > 0),
      map(val => val.sort((a, b) => a.name.localeCompare(b.name)))
    )

  allCurrentUserPermissions$ = this.allCurrentUserRoles$.pipe(
    map(roles => roles.flatMap(role => role.permissions))
  )

  loading$ = this.store.pipe(select(CurrentUserSelectors.selectLoading))
  loading = this.store.selectSignal(CurrentUserSelectors.selectLoading)

  allCurrentUserTenants$ = this.store.pipe(
    select(CurrentUserSelectors.selectAllCurrentUserTenants),
    map(tenants =>
      tenants
        .filter(tenant => tenant.isActive)
        .sort((a, b) => a.description.localeCompare(b.description))
    ),
    filter(tenants => tenants.length > 0)
  )

  allCurrentUserTenants = toSignal(this.allCurrentUserTenants$)

  currentTenant$ = this.store.pipe(
    select(CurrentUserSelectors.selectCurrentUserCurrentTenant),
    filter(tenant => tenant !== undefined),
    map(tenant => tenant as Tenant)
  )
  currentTenant = toSignal(this.currentTenant$, { initialValue: undefined })

  isCurrentTenantOrderV1 = computed(
    () => this.currentTenant()?.orderVersion === 1
  )
  isCurrentTenantOrderV2 = computed(
    () => this.currentTenant()?.orderVersion === 2
  )

  menuGroups$ = this.store.pipe(
    select(CurrentUserSelectors.selectMenu),
    filter(menu => menu !== undefined),
    map(menu => <MenuGroup[]>menu)
  )

  permissions$ = this.allCurrentUserRoles$.pipe(
    map(roles => roles.flatMap(role => role.permissions)),
    map(permissions =>
      permissions.map(permission => ({
        id: permission.permissionId,
        name: permission.permissionName
      }))
    )
  ) as Observable<Array<{ id: number; name: string }>>

  permissionsList$ = this.permissions$.pipe(
    map(permission => permission.map(x => x.name))
  )

  permissionsList = toSignal(this.permissionsList$, { initialValue: [] })

  userProfile$ = this.store.pipe(
    select(CurrentUserSelectors.selectCurrentUserProfile),
    filter(userProfile => userProfile !== undefined),
    map(userProfile => userProfile as UserProfile)
  )

  userProfile = this.store.selectSignal(
    CurrentUserSelectors.selectCurrentUserProfile
  )

  loadCurrentUser() {
    this.store.dispatch(currentUserRolesActions.loadRoles())
    this.store.dispatch(currentUserTenantsActions.loadTenants())
    this.store.dispatch(currentUserTenantsActions.loadCurrentTenantDetails())
    this.store.dispatch(currentUserActions.loadUserProfile())
  }

  async initializeFeatureFlags() {
    const currentUserProfile = await firstValueFrom(this.userProfile$)
    const currentTenant = await firstValueFrom(this.currentTenant$)
    this.featureFlagService.initialize(
      {
        currentUserEmail: currentUserProfile.email,
        currentTenantUuid: currentTenant.uuid
      },
      client => {
        const allFeatureFlags = client.allFlags()
        const featureFlagsMapped = Object.entries(FeatureFlags).reduce(
          (acc, [, featureFlagName]) => {
            const value = this.getFeatureFlagValue(
              allFeatureFlags,
              featureFlagName
            )
            return {
              ...acc,
              [featureFlagName]: value
            }
          },
          {} as FeatureFlagsState
        )
        this.store.dispatch(
          currentUserActions.setFeatureFlags({
            featureFlags: featureFlagsMapped
          })
        )
      }
    )
  }

  private getFeatureFlagValue(
    allFeatureFlags: LDFlagSet,
    featureFlagName: FeatureFlags
  ) {
    switch (featureFlagName) {
      default:
        return allFeatureFlags[featureFlagName] ?? false
    }
  }

  endLoading(operation: AsyncOperations) {
    this.store.dispatch(
      currentUserActions.setLoading({
        operation,
        loading: LoadingStatuses.NotStarted
      })
    )
  }

  loadCurrentUserDetails() {
    this.store.dispatch(currentUserActions.loadUserProfile())
  }

  updateCurrentUserDetails(currentUser: CurrentUserForm['profile']) {
    this.store.dispatch(
      currentUserActions.updateUserProfile({
        profile: currentUser
      })
    )
  }

  loadCurrentUserMenu() {
    this.store.dispatch(currentUserActions.loadCurrentUserMenu())
  }

  loadCurrentUserTenant() {
    this.store.dispatch(currentUserTenantsActions.loadCurrentTenantDetails())
  }

  loadCurrentUserTenants() {
    this.store.dispatch(currentUserTenantsActions.loadTenants())
  }

  switchCurrentUserTenant(tenantId: number) {
    this.store.dispatch(currentUserActions.switchCurrentTenant({ tenantId }))
  }

  login(email: string) {
    this.store.dispatch(currentUserActions.loginUser({ email }))
  }

  resetPassword(email: string) {
    this.store.dispatch(currentUserActions.resetPassword({ email }))
  }

  getFeatureFlagSignal(featureFlag: FeatureFlags) {
    return computed(() => this.allFeatureFlags()[featureFlag])
  }

  getFeatureFlag(featureFlag: FeatureFlags) {
    return this.allFeatureFlags()[featureFlag]
  }
}
