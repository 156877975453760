import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'
import { createReducer, on, Action, createFeature } from '@ngrx/store'
import {
  currentUserActions,
  currentUserRolesActions,
  currentUserTenantsActions
} from './current-user.actions'
import { Role } from '../domain/role.model'
import { Tenant } from '../domain/tenant.model'
import {
  LoadingState,
  LoadingStatuses,
  getDefaultLoadingState,
  updateLoadingState
} from '@navix/shared/loading'
import { AsyncOperations } from '../domain/current-user-loading.model'

import { UserProfile } from '../domain/user-profile.model'
import { FeatureFlags, FeatureFlagsState } from '../domain/feature-flags.model'
import { MenuGroup } from '../domain/menu-group.model'

export const CURRENT_USER_FEATURE_KEY = 'feature-current-user'

export interface CurrentUserState {
  roles: EntityState<Role>
  tenants: EntityState<Tenant>
  currentTenantId: number | undefined
  loading: LoadingState<typeof AsyncOperations>
  menuGroups: MenuGroup[] | undefined
  userProfile: UserProfile | undefined
  featureFlags: FeatureFlagsState
}

export interface CurrentUserPartialState {
  readonly [CURRENT_USER_FEATURE_KEY]: CurrentUserState
}

export const roleAdapter: EntityAdapter<Role> = createEntityAdapter<Role>()
export const tenantAdapter: EntityAdapter<Tenant> =
  createEntityAdapter<Tenant>()

export const initialCurrentUserState: CurrentUserState = {
  roles: roleAdapter.getInitialState(),
  tenants: tenantAdapter.getInitialState(),
  currentTenantId: undefined,
  loading: getDefaultLoadingState(AsyncOperations),
  menuGroups: undefined,
  userProfile: undefined,
  featureFlags: Object.keys(FeatureFlags).reduce(
    (acc, key) => ({
      ...acc,
      [key]: undefined
    }),
    {} as FeatureFlagsState
  )
}

const reducer = createReducer(
  initialCurrentUserState,
  on(currentUserRolesActions.loadRolesSuccess, (state, { roles }) => ({
    ...state,
    roles: roleAdapter.setAll(roles, state.roles)
  })),
  on(currentUserTenantsActions.loadTenantsSuccess, (state, { tenants }) => ({
    ...state,
    tenants: tenantAdapter.upsertMany(tenants, state.tenants)
  })),
  on(
    currentUserTenantsActions.loadCurrentTenantDetailsSuccess,
    (state, { tenant }) => ({
      ...state,
      currentTenantId: tenant.id,
      tenants: tenantAdapter.upsertOne(tenant, state.tenants)
    })
  ),
  on(
    currentUserActions.setLoading,
    (state, { operation, loading, message }): CurrentUserState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation,
        status: loading,
        message: message ?? ''
      })
    })
  ),
  on(currentUserActions.loadUserProfile, (state: CurrentUserState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.getUserProfile,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    currentUserActions.loadUserProfileSuccess,
    (state: CurrentUserState, { userProfile }) => ({
      ...state,
      userProfile,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getUserProfile,
        status: LoadingStatuses.Completed
      })
    })
  ),
  on(currentUserActions.loadUserProfileFail, (state: CurrentUserState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.getUserProfile,
      status: LoadingStatuses.Failed,
      message: "There's an error trying to load current user details."
    })
  })),
  on(currentUserActions.updateUserProfile, (state: CurrentUserState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.updateDetails,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    currentUserActions.updateUserProfileSuccess,
    (state: CurrentUserState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateDetails,
        status: LoadingStatuses.Completed,
        message: 'Current user details updated.'
      })
    })
  ),
  on(currentUserActions.updateUserProfileFail, (state: CurrentUserState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.updateDetails,
      status: LoadingStatuses.Failed,
      message: "There's an error trying to update current user details."
    })
  })),
  on(currentUserActions.loadCurrentUserMenu, (state: CurrentUserState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.getMenu,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    currentUserActions.loadCurrentUserMenuSuccess,
    (state: CurrentUserState, { menu }) => ({
      ...state,
      menuGroups: menu,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getMenu,
        status: LoadingStatuses.Completed
      })
    })
  ),
  on(currentUserActions.loadCurrentUserMenuFail, (state: CurrentUserState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.getMenu,
      status: LoadingStatuses.Failed,
      message: "There's an error trying to load current user menu."
    })
  })),
  on(currentUserActions.switchCurrentTenant, (state: CurrentUserState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.switchTenant,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    currentUserActions.switchCurrentTenantSuccess,
    (state: CurrentUserState, { tenantId }) => ({
      ...state,
      currentTenantId: tenantId,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.switchTenant,
        status: LoadingStatuses.Completed
      })
    })
  ),
  on(currentUserActions.switchCurrentTenantFail, (state: CurrentUserState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.switchTenant,
      status: LoadingStatuses.Failed,
      message: "There's an error trying to switch current user tenant."
    })
  })),
  on(currentUserActions.loginUser, (state: CurrentUserState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.login,
      status: LoadingStatuses.InProgress
    })
  })),
  on(currentUserActions.loginUserSuccess, (state: CurrentUserState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.login,
      status: LoadingStatuses.Completed
    })
  })),
  on(currentUserActions.loginUserFail, (state: CurrentUserState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.login,
      status: LoadingStatuses.Failed,
      message: "There's an error trying to login current user."
    })
  })),
  on(currentUserActions.loadUserProfileSuccess, (state, { userProfile }) => ({
    ...state,
    userProfile
  })),
  on(currentUserActions.updateFeatureFlag, (state, { key, value }) => ({
    ...state,
    featureFlags: {
      ...state.featureFlags,
      [key]: value
    }
  })),
  on(currentUserActions.setFeatureFlags, (state, { featureFlags }) => ({
    ...state,
    featureFlags
  })),
  on(currentUserActions.resetPassword, state => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.resetPassword,
      status: LoadingStatuses.InProgress
    })
  })),
  on(currentUserActions.resetPasswordSuccess, state => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.resetPassword,
      status: LoadingStatuses.Completed
    })
  })),
  on(currentUserActions.resetPasswordFail, state => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.resetPassword,
      status: LoadingStatuses.Failed,
      message: "There's an error trying to reset current user password."
    })
  }))
)

export const currentUserFeature = createFeature({
  name: CURRENT_USER_FEATURE_KEY,
  reducer: reducer
})

export function currentUserReducer(
  state: CurrentUserState | undefined,
  action: Action
) {
  return reducer(state, action)
}
