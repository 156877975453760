import { IStrategy } from '@navix/utils/adapter'
import { CurrentUserForm } from '../domain/current-user-form.model'
import { UpdateCurrentUserProfileRequestFormData } from '../domain/update-current-user.request'

export class ToUpdateUserProfileRequest
  implements
    IStrategy<
      CurrentUserForm['profile'],
      UpdateCurrentUserProfileRequestFormData
    >
{
  convert(
    userProfile: CurrentUserForm['profile']
  ): UpdateCurrentUserProfileRequestFormData {
    const formData: UpdateCurrentUserProfileRequestFormData = []

    formData.push({ key: 'firstName', value: userProfile.firstName })
    formData.push({ key: 'lastName', value: userProfile.lastName })
    formData.push({
      key: 'phoneNumber',
      value: userProfile.phoneNumber ?? ''
    })
    formData.push({ key: 'title', value: userProfile.title ?? '' })
    formData.push({
      key: 'timeZoneId',
      value: String(userProfile.timezoneId)
    })
    formData.push({
      key: 'shouldUpdateAvatar',
      value: (userProfile.shouldUpdateAvatar ?? false).toString()
    })
    formData.push({
      key: 'avatarContent',
      value: userProfile.avatar as File
    })

    return formData
  }
}
