import { CurrentUser } from '../domain/current-user.model'
import { GetCurrentUserTenantsResponse } from '../domain/get-current-user-tenants.response'

import { Tenant } from '../domain/tenant.model'
import { IStrategy } from '@navix/utils/adapter'

export class FromGetCurrentUserTenants
  implements IStrategy<GetCurrentUserTenantsResponse, CurrentUser>
{
  convert(response: GetCurrentUserTenantsResponse) {
    const tenants = response.map(
      tenant =>
        <Tenant>{
          id: tenant.tenantId,
          uuid: tenant.tenantUuid,
          description: tenant.tenantDescription,
          notificationEmailSender: tenant.notificationEmailSender,
          isActive: tenant.isActive ?? false
        }
    )

    return { tenants } as CurrentUser
  }
}
