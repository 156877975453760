import { IStrategy } from '@navix/utils/adapter'
import { CurrentUser } from '../domain/current-user.model'
import { GetCurrentUserRolesResponse } from '../domain/get-current-user-roles.response'
import { Role } from '../domain/role.model'

export class FromGetCurrentUserRoles
  implements IStrategy<GetCurrentUserRolesResponse, CurrentUser>
{
  convert(response: GetCurrentUserRolesResponse): CurrentUser {
    const roles = response.map(
      role =>
        <Role>{
          ...role,
          id: role.roleId
        }
    )

    return { roles } as CurrentUser
  }
}
