import { Injectable, inject } from '@angular/core'
import {
  LDClient,
  LDContext,
  LDMultiKindContext,
  initialize
} from 'launchdarkly-js-client-sdk'
import { LAUNCH_DARKLY_CLIENT_ID } from '@navix/utils/tokens'

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private readonly clientSideId = inject(LAUNCH_DARKLY_CLIENT_ID)
  initialize(
    {
      currentUserEmail,
      currentTenantUuid
    }: {
      currentUserEmail: string
      currentTenantUuid: string
    },
    successCallback: (client: LDClient) => void
  ) {
    const context: LDContext = {
      kind: 'user',
      key: currentUserEmail
    }
    const context2: LDMultiKindContext = {
      kind: 'multi',

      'tenant-uuid': {
        key: currentTenantUuid
      },
      user: {
        key: currentUserEmail
      }
    }

    const ldclient = initialize(this.clientSideId, context2)

    ldclient.on('initialized', () => {
      console.log('LD initialized')
    })
    ldclient.on('failed', () => {
      console.error('LD failed to initialize')
    })
    ldclient.on('ready', successCallback.bind(null, ldclient))
    ldclient.on('change', successCallback.bind(null, ldclient))
    ldclient.close()
  }
}
