import { provideState } from '@ngrx/store'
import { currentUserFeature } from '../+state/current-user.reducer'
import { CurrentUserFacade } from '../+state/current-user.facade'
import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core'
import { provideEffects } from '@ngrx/effects'
import { CurrentUserEffects } from '../+state/current-user.effects'

export function provideCurrentUserFeature(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideEffects(CurrentUserEffects),
    provideState(currentUserFeature),
    CurrentUserFacade
  ])
}
