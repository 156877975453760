import { IStrategy } from '@navix/utils/adapter'
import { GetCurrentUserMenuResponse } from '../domain/get-current-user-menu.response'
import { MenuGroup } from '../domain/menu-group.model'
import { KnownMenuIcons } from '../providers/menu-icons.provider'

export class FromGetCurrentUserMenu
  implements IStrategy<GetCurrentUserMenuResponse, MenuGroup[]>
{
  convert(response: GetCurrentUserMenuResponse): MenuGroup[] {
    const menuGroupsFromBackend = response.menuSections.map<MenuGroup>(
      section => ({
        name: section.name,
        options: section.options.map<MenuGroup['options'][number]>(option => ({
          name: option.name,
          iconName:
            KnownMenuIcons[option.key] ??
            KnownMenuIcons[section.key + '-' + option.key],
          redirectTo: [section.key, option.key]
        })),
        hasSeparation: true
      })
    )
    return menuGroupsFromBackend
  }
}
