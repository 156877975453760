export enum FeatureFlags {
  testFlag = 'test-flag',
  useNewInvoiceCentricDisputes = 'use-new-invoice-centric-disputes',
  viewCustomerChargeSetSummary = 'view-customer-charge-set-summary',
  displayLegacyNotes = 'display-legacy-notes',
  massApproveInvoices = 'mass-approve-invoices',
  unmatchedDocuments = 'unmatched-documents',
  useMultimodalOrderUi = 'use-multimodal-order-ui',
  useWorkInstructions = 'use-work-instructions',
  useNewAuditEndpoints = 'use-new-audit-endpoints',
  useWorkInstructionsPhase2 = 'use-work-instructions-phase-2',
  useDisputeReasonsV2Filter = 'use-dispute-reasons-v2-filter',
  docuementManagement = 'document-management',
  useDisputeRepliesList = 'use-dispute-replies-list',
  businesRules = 'business-rules'
}

// eslint-disable-next-line @typescript-eslint/ban-types
type CustomFeatureFlagState = {}
type InferFeatureFlagsState<
  T extends Record<string, string>,
  U extends Record<string, unknown>,
  K extends keyof T = keyof T
> = {
  [P in T[K]]: (P extends keyof U ? U[P] : boolean) | undefined
}

export type FeatureFlagsState = InferFeatureFlagsState<
  typeof FeatureFlags,
  CustomFeatureFlagState
>
