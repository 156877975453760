import { Injectable, inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import {
  API_URL,
  API_URL_IDENTITY,
  AUTH_CLIENT_ID,
  AUTH_URL,
  HEADER_API_KEY
} from '@navix/utils/tokens'
import { GetCurrentUserRolesResponse } from '../domain/get-current-user-roles.response'
import { GetCurrentTenantDetailsResponse } from '../domain/get-current-tenant-details.response'
import { GetCurrentUserDetailsResponse } from '../domain/get-current-user-details.response'
import { GetCurrentUserMenuResponse } from '../domain/get-current-user-menu.response'
import { GetOrganizationResponse } from '../domain/get-organization.response'
import { GetUserProfileResponse } from '../domain/get-user-profile.response'
import { GetCurrentUserTenantsResponse } from '../domain/get-current-user-tenants.response'
import { UpdateCurrentUserProfileRequestFormData } from '../domain/update-current-user.request'

@Injectable({
  providedIn: 'root'
})
export class CurentUserService {
  private readonly _http = inject(HttpClient)
  private readonly apiUrl = inject(API_URL)
  private readonly authUrl = inject(AUTH_URL)
  private readonly authClientId = inject(AUTH_CLIENT_ID)
  private readonly apiUrlIdentity = inject(API_URL_IDENTITY)
  private readonly apiKey = inject(HEADER_API_KEY)
  private readonly apiAuthUrl = `${this.apiUrl}auth/`
  private readonly apiCurrentUserUrl = `${this.apiUrl}auth/users/` as const
  private readonly headersWithApiKey: { [key: string]: string } = {
    ...this.apiKey
  } as { [key: string]: string }

  getCurrentUserRoles(): Observable<GetCurrentUserRolesResponse> {
    return this._http.get<GetCurrentUserRolesResponse>(
      `${this.apiCurrentUserUrl}roles`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getCurrentUserTenants(): Observable<GetCurrentUserTenantsResponse> {
    return this._http.get<GetCurrentUserTenantsResponse>(
      `${this.apiCurrentUserUrl}tenants`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getCurrentTenantDetails(): Observable<GetCurrentTenantDetailsResponse> {
    return this._http.get<GetCurrentTenantDetailsResponse>(
      `${this.apiUrl}tenants/current`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getCurrentUserDetails(): Observable<GetCurrentUserDetailsResponse> {
    return this._http.get<GetCurrentUserDetailsResponse>(
      `${this.apiCurrentUserUrl}current`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getUserProfile(): Observable<GetUserProfileResponse> {
    return this._http.get<GetUserProfileResponse>(
      `${this.apiCurrentUserUrl}current`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateTenantDetails(
    request: UpdateCurrentUserProfileRequestFormData
  ): Observable<object> {
    const formData = new FormData()
    request.forEach(entry => formData.append(entry.key, entry.value))
    return this._http.put(`${this.apiCurrentUserUrl}current`, formData, {
      headers: { ...this.headersWithApiKey }
    })
  }

  getCurrentUserMenu(): Observable<GetCurrentUserMenuResponse> {
    return this._http.get<GetCurrentUserMenuResponse>(
      `${this.apiAuthUrl}menu/current`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  switchTenant(tenantId: number) {
    return this._http.patch<void>(
      `${this.apiCurrentUserUrl}tenants/${tenantId}/current`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getOrganizationId(email: string): Observable<GetOrganizationResponse> {
    return this._http.get<GetOrganizationResponse>(
      `${this.apiUrlIdentity}/identity/v1/${email}/organization`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  forgotPassword(email: string) {
    return this._http.post(
      `https://${this.authUrl}/dbconnections/change_password`,
      {
        email,
        client_id: this.authClientId
      },
      { responseType: 'text' }
    )
  }
}
