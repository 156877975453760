import { Injectable, inject } from '@angular/core'
import { NonNullableFormBuilder, Validators } from '@angular/forms'
import { CurrentUserFormControls } from '../domain/current-user-form.model'
import { UserProfile } from '../domain/user-profile.model'

@Injectable({ providedIn: 'root' })
export class CurrentUserFormService {
  private readonly fb = inject(NonNullableFormBuilder)

  getCurrentUserForm(value?: UserProfile): CurrentUserFormControls['profile'] {
    return this.fb.group({
      avatarUrl: this.fb.control<string>(value?.avatarUrl ?? ''),
      firstName: this.fb.control<string>(
        value?.firstName ?? '',
        Validators.required
      ),
      lastName: this.fb.control<string>(
        value?.lastName ?? '',
        Validators.required
      ),
      title: this.fb.control<string | undefined>(value?.title ?? ''),
      phoneNumber: this.fb.control<string | undefined>(
        value?.phoneNumber ?? ''
      ),
      email: this.fb.control<string | undefined>(value?.email ?? ''),
      timezoneId: this.fb.control<number | undefined>(
        value?.timezoneId,
        Validators.required
      ),
      shouldUpdateAvatar: this.fb.control<boolean | undefined>(false),
      avatar: this.fb.control<File | undefined>(undefined)
    })
  }
}
