import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { Role } from '../domain/role.model'
import { Tenant } from '../domain/tenant.model'
import { AsyncOperations } from '../domain/current-user-loading.model'
import { LoadingStatuses } from '@navix/shared/loading'
import { CurrentUserForm } from '../domain/current-user-form.model'

import { UserProfile } from '../domain/user-profile.model'
import { FeatureFlags, FeatureFlagsState } from '../domain/feature-flags.model'
import { MenuGroup } from '../domain/menu-group.model'

export const currentUserRolesActions = createActionGroup({
  source: 'Current User / Roles',
  events: {
    'load roles': emptyProps(),
    'load roles success': props<{ roles: Role[] }>()
  }
})

export const currentUserTenantsActions = createActionGroup({
  source: 'Current User / Tenants',
  events: {
    'load tenants': emptyProps(),
    'load tenants success': props<{ tenants: Tenant[] }>(),

    'load current tenant details': emptyProps(),
    'load current tenant details success': props<{ tenant: Tenant }>()
  }
})

export const currentUserActions = createActionGroup({
  source: 'Current User',
  events: {
    'set loading': props<{
      operation: AsyncOperations
      loading: LoadingStatuses
      message?: string
    }>(),
    'load current user menu': emptyProps(),
    'load current user menu fail': props<{ error: unknown }>(),
    'load current user menu success': props<{ menu: MenuGroup[] }>(),

    'switch current tenant': props<{ tenantId: number }>(),
    'switch current tenant success': props<{ tenantId: number }>(),
    'switch current tenant fail': props<{ error: unknown }>(),

    'login user': props<{ email: string }>(),
    'login user success': props<{ organizationId: string; email: string }>(),
    'login user fail': props<{ error: unknown }>(),

    'reset password': props<{ email: string }>(),
    'reset password success': emptyProps(),
    'reset password fail': props<{ error: unknown }>(),

    'load user profile': emptyProps(),
    'load user profile success': props<{ userProfile: UserProfile }>(),
    'load user profile fail': props<{ error: unknown }>(),

    'update user profile': props<Pick<CurrentUserForm, 'profile'>>(),
    'update user profile success': emptyProps(),
    'update user profile fail': props<{ error: unknown }>(),

    'update feature flag': props<{
      key: FeatureFlags
      value: boolean
    }>(),
    'set feature flags': props<{
      featureFlags: FeatureFlagsState
    }>()
  }
})
