import { GetUserProfileResponse } from '../domain/get-user-profile.response'

import { IStrategy } from '@navix/utils/adapter'
import { UserProfile } from '../domain/user-profile.model'

export class FromGetUserProfileResponse
  implements IStrategy<GetUserProfileResponse, UserProfile>
{
  convert(response: GetUserProfileResponse) {
    return <UserProfile>{
      email: response.email,
      fullName: `${response.firstName} ${response.lastName}`,
      avatarUrl: response.avatarUrl,

      guid: response.userGuid,
      id: response.userId,
      firstName: response.firstName,
      lastName: response.lastName,
      phoneNumber: response.phoneNumber,
      title: response.title,
      timezoneId: response.timeZoneId,
      timezoneDescription: response.timezoneDisplayName
    }
  }
}
